[data-video-overlay-wrapper] {
  @apply relative left-0 top-0 flex flex-col md:flex-row aspect-video w-full items-center justify-center bg-gray-900 text-white;
  [data-dismiss] {
    @apply self-end md:absolute right-2 top-2 z-40 flex items-center gap-1 rounded px-3 py-2 font-tt-medium text-gray-200 transition hover:bg-gray-800;
    svg {
      @apply h-5 w-5;
    }
  }
  [data-content] {
    @apply flex min-h-full w-full flex-col items-center justify-center text-center text-lg leading-relaxed pb-5 sm:pb-0;
  }
}
[data-video-overlay='default'] {
  @apply px-5;
  [data-image] {
    @apply hidden items-center justify-center sm:flex w-28 sm:w-48 lg:w-64;
  }
  [data-title] {
    @apply sm:pt-4 text-xl font-tt-medium sm:text-3xl;
  }
  [data-byline] {
    @apply font-tt-regular text-gray-200;
  }
  [data-actions] {
    @apply flex flex-wrap items-center justify-center gap-5 py-4 sm:py-8;
    [data-action='replay'] {
      @apply rounded-full bg-gray-800 px-3 py-1 text-xl font-tt-medium transition hover:bg-gray-700 sm:px-5 sm:py-3 space-x-2;
      [data-icon] {
      }
    }
    [data-action='try-again'] {
      @apply flex items-center gap-1 rounded-full bg-gray-800 px-3 py-1 text-xl font-tt-medium transition hover:bg-gray-700 sm:px-5 sm:py-3;
      [data-icon] {
        @apply h-5 w-5;
      }
    }
    [data-action='continue'] {
      @apply rounded-full bg-tjs-orange px-3 py-1 text-xl font-tt-medium transition hover:brightness-125 sm:px-5 sm:py-3 relative;
      [data-icon] {
      }
      svg {
        @apply w-5 h-5;
      }
    }
  }
}
[data-video-overlay='blocked'] {
  @apply flex w-full flex-col items-center justify-center bg-gray-900 p-5 text-white xl:flex-row;
  [data-pricing-product-image],
  [data-pricing-footer],
  [data-includes],
  [data-bonus],
  [data-modules],
  [data-pricing-product-header] [data-title] {
    @apply hidden;
  }
  [data-pricing-product-header] {
    [data-byline] {
      @apply hidden;
    }
  }
  [data-pricing-product-header] {
    [data-byline] {
      @apply hidden;
    }
    [data-name-badge] {
    }
    [data-price-container] {
      @apply flex items-center justify-center gap-0.5 font-tt-medium;
      sup {
        @apply -translate-y-2 align-sub text-base font-black text-gray-300;
      }
      [data-price] {
        @apply flex gap-0.5 text-5xl font-black;
      }
      span {
        @apply pl-0.5 pt-0.5 align-super text-gray-200;
      }

      [data-price-discounted] {
        @apply flex flex-col items-center pl-5 font-semibold;

        [data-full-price] {
          @apply relative flex text-2xl text-gray-200 before:absolute before:left-0 before:top-1/2 before:h-0.5 before:w-full before:bg-gray-200/80;

          span {
            @apply text-sm;
          }
        }
        [data-percent-off] {
          @apply text-base text-pink-300;
        }
      }
    }
    [data-pricing-product-sale-countdown] {
      @apply mt-5 flex w-full flex-col rounded-lg border-2 border-white/10 px-5 py-5 text-center;
      [data-grid] {
        @apply mx-auto grid grid-cols-4 items-center justify-center gap-3 tabular-nums tracking-tight sm:max-w-[300px];
      }
      [data-title] {
        @apply pb-4 text-sm font-medium sm:text-base;
      }
      [data-number] {
        @apply font-tt-medium text-2xl font-black leading-none sm:text-3xl;
      }
      [data-label] {
        @apply pt-1 text-xs font-semibold uppercase tracking-wide opacity-75;
      }
    }
  }
  [data-price-container='loading'] {
    @apply flex items-center py-3;
    svg {
      @apply text-white;
    }
  }
  [data-purchased-container] {
    [data-purchased] {
      @apply flex items-center justify-center gap-1;
      svg {
        @apply h-8 w-8;
      }
    }
    [data-unavailable] {
      @apply py-4 text-center font-tt-medium text-lg font-bold text-blue-400;
    }
  }
  [data-purchase-container] {
    @apply pt-8;
    form {
      fieldset {
        [data-guarantee] {
          @apply block pt-3 text-center text-sm text-gray-200;
        }
        legend {
        }
        [data-team-switch] {
          @apply flex items-center justify-center gap-2 pb-3.5 text-base;
          label {
            @apply sr-only;
          }
          button[role='button'] {
            @apply text-gray-200 decoration-gray-600 underline-offset-2 transition hover:underline;
          }
          button[role='switch'] {
            @apply relative h-6 w-[47px] rounded-full border border-gray-300/50 bg-gray-800 shadow-md shadow-black/30 transition hover:bg-gray-700 radix-state-checked:bg-gray-800 hover:radix-state-checked:bg-gray-700;
            span {
              @apply block h-[18px] w-[18px] translate-x-[2px] rounded-full bg-gray-300 shadow-sm shadow-black/50 transition-all will-change-transform group-hover:bg-gray-300 radix-state-checked:translate-x-[25px] radix-state-checked:bg-tjs-orange group-hover:radix-state-checked:bg-blue-300;
            }
          }
        }
        [data-quantity-input] {
          @apply mb-5 flex w-full flex-col items-center justify-center px-5 xl:px-12;
          div {
            @apply flex items-center gap-1 text-sm font-medium;
            label {
              @apply mr-3;
            }
            input {
              @apply max-w-[70px] rounded-md border border-gray-600 bg-gray-800 py-2 pl-3 font-mono font-bold ring-blue-500;
            }
            button {
              @apply flex h-full items-center justify-center rounded bg-gray-700/50 px-3 py-2 font-mono sm:hidden;
            }
          }
        }
      }
      [data-pricing-product-checkout-button] {
        @apply rounded border duration-200 font-tt-demibold w-full block mt-6 px-5 py-4 text-lg sm:text-xl leading-snug bg-gradient-to-b from-tjs-yellow to-tjs-orange text-white border-none;
        box-shadow: inset 1px -1px 3px 0 #f0a233;
        text-shadow: 0 1px 2px #c98b33;
        span {
          @apply duration-200 block;
        }
      }

      [data-pricing-product-checkout-button]:disabled {
        @apply cursor-wait;
      }
    }
  }

  [data-quantity-input] {
    div {
      @apply flex items-center justify-center gap-1 text-lg font-tt-medium;
    }
    input[type='number'] {
      @apply max-w-[70px] rounded-md border border-gray-200 bg-gray-200/60 py-1 pl-3 font-mono font-bold ring-tjs-orange;
    }
    button {
      @apply flex h-full items-center justify-center rounded border border-gray-200 px-3 py-2 font-mono sm:hidden;
    }
  }
  [data-thumbnail] {
    @apply object-cover object-center opacity-20 blur-sm contrast-125;
  }
  [data-subscribe] {
    @apply z-20 flex h-full flex-shrink-0 flex-col items-center justify-center gap-5 p-5 pb-10 text-center text-lg leading-relaxed sm:p-10 sm:pb-16;
    [data-col='1'] {
      @apply flex w-full flex-col items-center justify-center gap-2;
      [data-image] {
        @apply relative flex items-center justify-center rounded-full bg-white p-5;
      }
      [data-title] {
        @apply pt-4 text-3xl font-tt-demibold;
      }
      [data-subtitle] {
        @apply pb-5 text-lg opacity-80 lg:text-xl;
      }
      [data-nospam] {
        @apply pt-2 text-base opacity-80;
      }
    }
  }
  [data-col='2'] {
    @apply sm:pr-5;
    [data-markdown] {
      @apply prose prose-sm relative flex w-full max-w-md flex-col rounded-lg border border-gray-700 bg-gray-800 p-8 text-left 2xl:prose-base before:absolute before:left-1/2 before:top-[-8px] before:h-4 before:w-4 before:rotate-45 before:border-l before:border-t before:border-gray-700/50 before:bg-gray-800 before:content-[''] prose-p:mb-0 prose-p:text-gray-100 xl:before:hidden xl:prose-p:mb-0 2xl:prose-p:mb-0;
      [data-title] {
        @apply mt-0 text-2xl font-tt-medium text-white sm:text-3xl;
      }
    }
  }
  [data-buy] {
    @apply z-20 flex h-full flex-shrink-0 flex-col items-center justify-center gap-5 pb-5 text-center text-lg leading-relaxed sm:p-10;
    [data-team-purchase] {
      @apply flex w-full flex-col items-center justify-center gap-2;
      [data-image] {
        @apply relative -mb-5;
      }
      [data-title] {
        @apply pt-5 text-4xl font-tt-demibold;
      }
      [data-subtitle] {
        @apply max-w-xl pb-5 pt-3 text-lg text-gray-300;
      }
      [data-self-redeem-button] {
        @apply rounded-full bg-tjs-orange px-6 py-3 text-base font-tt-medium text-white transition hover:brightness-125;
      }
      [data-self-redeem-button][disabled] {
      }
      [data-invite-team] {
        @apply mt-3 text-center text-base text-white underline;
      }
    }
    [data-pricing] {
      @apply flex w-full flex-col items-center justify-center;
      [data-col='1'] {
        @apply flex w-full flex-col items-center;
        [data-image] {
          @apply w-28 sm:w-48 lg:w-64;
        }
        [data-title] {
          @apply pt-5 text-2xl sm:text-3xl md:text-4xl font-tt-demibold;
        }
        [data-description] {
          @apply w-full pb-10 pt-3 text-xl md:text-2xl opacity-90;
          [data-markdown] {
            @apply prose w-full prose-p:text-gray-300;
          }
        }
        [data-includes] {
          @apply text-base text-gray-300;
          [data-modules] {
            @apply flex items-center justify-center gap-3 pt-3;
            a {
              @apply transition hover:scale-105;
            }
          }
        }
      }
      [data-col='2'] {
        @apply flex w-full flex-col items-center;
      }
    }
  }
}
[data-video-overlay='loading'] {
  div:first-of-type {
    @apply flex items-center justify-center;
  }
  [data-image] {
    @apply opacity-50 blur-sm brightness-50 contrast-125;
  }
  svg {
    @apply absolute h-8 w-8 text-white;
  }
}
[data-video-overlay='finished'] {
  @apply px-5;
  h2 {
    @apply text-2xl sm:text-3xl mb-6 sm:mb-8 flex flex-col items-center;
  }
  [data-title] {
    @apply text-lg font-tt-medium sm:text-2xl sm:font-tt-demibold;
  }
  [data-share-actions] {
    @apply flex items-center gap-2 py-8;
    [data-action='share'] {
      @apply flex items-center gap-2 rounded bg-gray-800 text-sm sm:text-base px-2 sm:px-3 py-2 hover:bg-gray-700;
    }
  }
  [data-actions] {
    @apply flex items-center justify-center divide-x divide-gray-700;
    [data-action='replay'] {
      @apply px-3 py-1 text-lg font-tt-medium transition hover:bg-gray-900 sm:px-5 sm:py-3 space-x-2 text-gray-400 hover:text-white;
    }
    [data-action='restart'] {
      @apply px-3 py-1 text-lg font-tt-medium transition hover:bg-gray-900 sm:px-5 sm:py-3 text-gray-400 hover:text-white border-none;
    }
  }
}
