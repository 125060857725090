[data-login-template] {
  @apply relative mx-auto flex w-full max-w-sm flex-grow flex-col items-center justify-start px-5 pb-16 pt-20 sm:p-5 sm:pt-32;
  [data-title] {
    @apply pt-3 text-center text-4xl font-extrabold leading-9 sm:pt-8 sm:text-4xl;
  }
  [data-verification-error] {
    @apply max-w-sm pt-4 text-center sm:mx-auto sm:w-full sm:pt-8;
  }
  [data-form] {
    @apply w-full pt-8 sm:mx-auto sm:max-w-md sm:pt-10 sm:text-lg;

    [data-input-container] {
      @apply relative mt-1 rounded-md shadow-sm;
      [data-icon] {
        @apply pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3;
        svg {
          @apply h-5 w-5;
        }
      }
      [data-input] {
        @apply pl-10;
      }
    }
    [data-button] {
      @apply mt-4 w-full transition hover:saturate-200;
    }
  }
  [data-separator] {
    @apply py-5 text-center text-sm opacity-60;
  }
  [data-providers-container] {
    @apply w-full space-y-2;
    [data-button] {
      @apply w-full gap-2;
    }
  }
  [data-sr-button] {
    @apply relative flex flex-shrink-0 items-center justify-center rounded-lg bg-blue-600 px-5 py-3 font-semibold text-white shadow-sm shadow-blue-800/20 ring-offset-1 transition hover:brightness-110 focus-visible:ring-blue-600;
    svg {
      @apply h-7 w-7;
    }
  }
  [data-sr-button][disabled] {
  }
}

[data-check-your-email] {
  @apply relative flex flex-grow flex-col items-center justify-center px-5 pb-16 pt-5;
  [data-container] {
    @apply mx-auto flex w-full max-w-md flex-col items-center gap-5 text-center;
  }
  [data-title] {
    @apply text-center text-3xl font-extrabold leading-9 sm:text-3xl lg:text-4xl;
  }
  [data-message] {
    @apply max-w-sm text-gray-600 dark:text-gray-300;
  }
}

[data-video-embed-page] {
  [data-login-template] {
    @apply relative mx-auto flex w-full max-w-md flex-grow flex-col items-center justify-start p-3 sm:p-5;
    [data-title] {
      @apply text-center text-2xl font-extrabold leading-tight sm:text-4xl;
    }
    [data-form] {
      @apply w-full pt-4 sm:mx-auto sm:max-w-sm sm:pt-10 sm:text-lg;
    }
    [data-providers-container] {
      @apply w-full sm:max-w-sm;
    }
  }
}
