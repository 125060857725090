/* Team page reset */

[data-team-page] {
  [data-invite-team] {
    /* reset styles because it is rendered within a card */
    @apply block rounded-none border-0 bg-transparent p-0 shadow-none;
  }
}

/* Purchase detail page reset */

#purchase-detail {
  [data-invite-team] {
    /* reset styles because it is rendered within a card */
    @apply flex w-full flex-col rounded-none border-none bg-transparent p-0 py-3 shadow-none;
  }
}

/* Shared form component */

[data-invite-team] {
  @apply flex flex-col rounded-lg border border-gray-100 bg-transparent p-5 shadow-xl shadow-gray-400/5;
  [data-title] {
    @apply py-3;
    /* 3 seats left */
    strong {
      @apply font-semibold;
    }
  }
  [data-copy-invite-link-container] {
    @apply w-full;
    [data-copy-invite-link] {
      /* optional */
    }
  }
  [data-redeem] {
    @apply mt-5 flex flex-col items-center gap-3 border-t border-gray-100 pt-5 sm:mt-8 sm:flex-row sm:justify-between;
    [data-title] {
      /* Or get access yourself */
      @apply flex items-center gap-1 font-semibold;
    }
    [data-self-redeem-button] {
      /* optional */
    }
  }
}

/* Card */

[data-team-card] {
  @apply w-full rounded-lg border border-gray-200 p-5 text-body shadow-xl shadow-black/10;
}

[data-team-card] > [data-content] {
  @apply pt-3;
}

[data-team-card] > [data-title] {
  @apply flex items-center gap-3 text-xl font-semibold;
}

/* Self redeem button */

[data-self-redeem-button] {
  @apply flex shrink-0 items-center gap-1 rounded-md px-5 py-2 text-lg font-semibold text-white transition hover:bg-indigo-700 ml-3 bg-indigo-600;
}
[data-self-redeem-button][disabled] {
  @apply cursor-not-allowed opacity-30;
}

/* Buy more seats form */

[data-buy-more-seats-form] {
  @apply pt-3;
  #team-upgrade-pricing-inline {
    @apply flex w-full items-center justify-between flex-col;
    [data-seats-form] {
      @apply inline-flex items-center gap-3 justify-center;
      label {
        @apply opacity-70;
      }
      input {
        @apply rounded-md border border-gray-800 py-2 pl-3 font-mono font-bold text-body;
      }
      button {
        @apply flex h-full items-center justify-center rounded bg-gray-800/60 px-3 py-2 font-mono sm:hidden;
      }
    }
    [data-pricing-product] {
      @apply flex items-center justify-center gap-5 mt-4;
      [data-pricing-product-header] {
        @apply flex items-center gap-5 flex-col w-full;
        [data-price-container] {
          @apply w-full flex justify-center items-center h-auto;
        }
      }
      sup {
        @apply mr-1 font-tt-demibold;
      }
      [data-price] {
        @apply flex items-center space-x-2 font-tt-demibold;
      }
      [data-price-discounted] {
        @apply flex flex-col items-center leading-none font-tt-regular;
      }
      [data-full-price] {
        @apply line-through;
      }
      [data-percent-off] {
        @apply text-base leading-none uppercase font-tt-demibold text-green-500;
      }
      button[type='submit'] {
        @apply flex shrink-0 items-center gap-1 rounded-md px-5 py-2 text-lg font-semibold text-white transition hover:bg-indigo-700 ml-3 bg-indigo-600 min-w-[80px] justify-center;
      }
    }
  }
}

/* Copy invite link form */
[data-copy-invite-link] {
  label {
    /* Invite share link */
    @apply sr-only;
  }
  div {
    @apply flex gap-3 pt-2;
  }
  input {
    @apply w-full grow rounded-md border border-gray-200 bg-transparent text-body px-3 py-2 text-sm font-semibold shadow-inner;
  }
  input[disabled] {
    @apply opacity-50;
  }
  [data-sr-button] {
    @apply flex shrink-0 items-center gap-1 rounded-md px-5 py-2 text-lg font-semibold text-white transition hover:bg-indigo-700 ml-3 bg-indigo-600;
  }
  [data-sr-button][disabled] {
    @apply cursor-not-allowed opacity-30;
  }
}

/* Claimed seats */

[data-claimed-seats-team] {
  [data-claimed-seat] {
    @apply text-gray-300;
  }
  [data-claimed-seat=''] {
    @apply inline-flex items-center gap-1.5 before:translate-y-[2px] before:content-[url("/assets/check.svg")];
  }
}
